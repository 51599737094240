import React, {useState} from 'react';
import {ethers} from "ethers";
import apeABI from './assets/BlindApeYachtClub.json';
import Twitter from './assets/Twitter.png';
import './App.css';

function App() {

    const [walletAddress, setWalletAddress] = useState("");
    const [supply, setSupply] = useState(0);
    const [num, setNum] = useState(1);

    const isConnected = Boolean(walletAddress);

    const apeAddress = '0xAA4C822aA0C4c8a0D5aAbBb8Eff59858a585017B';

    async function connectWallet() {
        if(window.ethereum) {
            window.ethereum
                .request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x1' }],
                })
                .then((result) => {
                    console.log(result);
                })
                .catch((error) => {
                    console.error(error);
                });
            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                setWalletAddress(accounts[0]);
                await handleSupply();
            } catch (error) {
                console.log('Error connecting...');
            }
        } else {
            alert('Meta Mask not detected');
        }
    }

    const handleChange = async (event) => {
        if(event.target.value >= 0 && event.target.value <= 50) {
            setNum(Number(event.target.value))
        }
    }

    async function handleSupply() {
        if (window.ethereum) {
            const provider = new ethers.BrowserProvider(window.ethereum)
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(
                apeAddress,
                apeABI.abi,
                signer
            );
            try {
                const response = await contract.totalSupply();
                setSupply(Number(response));
                console.log('response: ', response);
            } catch (err) {
                console.log("error: ", err);
            }
        }
    }

    async function handleFree() {
        if (window.ethereum) {
            const provider = new ethers.BrowserProvider(window.ethereum)
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(
                apeAddress,
                apeABI.abi,
                signer
            );
            try {
                const response = await contract.mintFree();
                console.log('response: ', response);
            } catch (err) {
                console.log("error: ", err);
            }
        }
    }

    async function handlePaid() {
        if (window.ethereum) {
            const provider = new ethers.BrowserProvider(window.ethereum)
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(
                apeAddress,
                apeABI.abi,
                signer
            );
            try {
                const response = await contract.mintPaid(num, {value: ethers.parseEther(String((num * 0.002).toFixed(2)))});
                console.log('response: ', response);
            } catch (err) {
                console.log("error: ", err);
            }
        }
    }

    return (
        <div className="App">
            <div className="navbar">
                {isConnected ? (
                    <button className="connect">
                        {walletAddress.substring(0, 6) + "..." + walletAddress.substring(38, 42)}
                    </button>
                ) : (
                    <button className="connect" onClick={connectWallet}>
                        CONNECT
                    </button>
                )}
                <a href="https://twitter.com/BlindApe_YC" target="_">
                    <img className="twitter" src={Twitter} alt=""/>
                </a>
                <h1 className="title">BLIND APE YACHT CLUB</h1>
            </div>
            <div className="main">
                {isConnected ? (
                    <div className="mint">
                        <button className="free-button" onClick={handleFree}>
                            MINT 3 FREE APES
                        </button>
                        <p> MAX 50 APES PER WALLET</p>
                        <div className="paid">
                            <input
                                className="num"
                                value={num}
                                onChange={handleChange}
                            />
                            <button className="paid-button" onClick={handlePaid}>
                                MINT {num} APES Ξ{num * 0.002}
                            </button>
                        </div>
                        <p> {supply} / 4269 </p>
                    </div>
                ) : (
                    <h1>
                        CONNECT WALLET <br/> TO MINT
                    </h1>
                )}
            </div>
        </div>
    );
}

export default App;
